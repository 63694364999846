<template>
  <div>
    <Container>
      <b-field label="Classroom" custom-class="is-large" v-if="currentTeachers !== undefined">
        <b-dropdown v-model="currentTeachers" multiple ref="dropdown" aria-role="list" :scrollable="true"
          :max-height="400">
          <button class="button is-large" type="button" slot="trigger">
            <span class="mr-5" v-if="currentTeachers.length == 1">{{
              currentTeachers[0].name
            }}</span>
            <span class="mr-5" v-else-if="currentTeachers.length > 1">Selected ({{ currentTeachers.length }})</span>
            <span class="mr-5" v-else>All Classrooms</span>

            <b-icon icon="chevron-down"></b-icon>
          </button>

          <b-dropdown-item custom aria-role="listitem">
            <b-checkbox @input="
              currentTeachers.length !== 0 ? (currentTeachers = []) : null
              " v-model="truey" :indeterminate="currentTeachers.length !== 0">
              All Classrooms
            </b-checkbox>
          </b-dropdown-item>

          <b-dropdown-item custom v-for="teacher in teachers" :value="teacher.name" aria-role="listitem"
            :key="teacher.name">
            <b-checkbox @input="$refs.dropdown.selectItem(teacher)" :native-value="teacher" v-model="currentTeachers">
              <span>{{ teacher.name }}</span>
            </b-checkbox>
          </b-dropdown-item>
        </b-dropdown>
      </b-field>

      <b-field>
        <b-switch
            v-model="markStudentsCleared"
            passive-type='is-info'
            type='is-success'>
            {{ markStudentsCleared ? "Marking students cleared" : "Marking students in-transit" }}
        </b-switch>
        </b-field>

      <div v-if="schoolMeta.classroom ? schoolMeta.classroom.includes('bus') : true
        ">
        <h2 class="title is-3 mt-5">Arrived Busses/Daycares</h2>

        <transition-group name="list-complete" tag="div" class="columns is-multiline is-mobile">
          <div class="
              column
              is-full-mobile is-half-tablet is-one-third-widescreen
              list-complete-item
            " v-for="bus in filteredBusses" :key="bus.name">
            <div class="card" style="
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              ">
              <div class="card-content">
                <div class="content">
                  <h3 class="title has-text-centered is-4 mb-0">
                    {{ bus.name }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <!-- <div class="" v-else>
        <p class="title is-5">No Data</p>
      </div> -->
      <div v-if="schoolMeta.classroom ? schoolMeta.classroom.includes('car') : true
        ">
        <h2 class="title is-3 mt-5">Car Riders</h2>
        <transition-group name="list-complete" tag="div" class="columns is-multiline is-mobile">
          <div class="
              column
              is-half-mobile is-one-third-tablet is-one-fifth-desktop
              list-complete-item
            " v-for="student in filteredStudents" :key="student.id">
            <div class="card" style="display: flex; flex-direction: column; height: 100%" :class="{
              'student-in-transit': student.state === 'STUDENT_IN_TRANSIT',
            }">
              <div @click="showModal(student.id)" style="
                  cursor: pointer;
                  flex: 1 1 0%;
                  display: flex;
                  flex-direction: column;
                " class="noSelect">
                <header class="card-header">
                  <p class="card-header-title">
                    {{ student.name }}
                  </p>
                </header>

                <div class="card-content" style="
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <div class="content">
                    <h3 class="title has-text-centered is-2">
                      {{ student.id }}
                    </h3>
                    <p class="subtitle has-text-centered is-5">
                      Station {{ student.station }}
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="!markStudentsCleared">
                <footer class="card-footer" v-if="student.state !== 'STUDENT_IN_TRANSIT'">
                  <a class="card-footer-item has-text-centered" @click="setCarState(student.id, 'STUDENT_IN_TRANSIT')">Mark
                    In-Transit</a>
                </footer>
                <footer class="card-footer" v-else>
                  <a class="card-footer-item has-text-centered" @click="setCarState(student.id, 'ARRIVED')">In-Transit</a>
                </footer>
              </div>
              <div v-else>
                <footer class="card-footer has-background-success-light">
                  <a class="card-footer-item has-text-centered" @click="setCarState(student.id, 'CLEARED')">Mark
                    Cleared</a>
                </footer>
              </div>

            </div>
          </div>
        </transition-group>


        <b-collapse 
          class="card" 
          animation="slide" 
          aria-id="cleared-students-collapse"
          :open="false">
          <template #trigger="props">
              <div
                  class="card-header"
                  role="button"
                  aria-controls="cleared-students-collapse"
                  :aria-expanded="props.open">
                  <p class="card-header-title">
                      Cleared Students (All Classrooms)
                  </p>
                  <a class="card-header-icon">
                      <b-icon
                          :icon="props.open ? 'chevron-down' : 'chevron-up'">
                      </b-icon>
                  </a>
              </div>
          </template>
            <CarDisplay
              icon="fas fa-undo"
              :filter="(state) => ['CLEARED'].includes(state)"
              actionState="ARRIVED"
              :hasAction="true"
            />
        </b-collapse>

      </div>
    </Container>

    <b-modal v-model="modalDisplay" :width="300" scroll="keep">
      <modal :modalData="modalData" :modalDisplay="modalDisplay" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "../components/Container";
import Modal from "../components/Modal.vue";
import { messageIncomingSound } from "../beep.js"
import { witchSound } from "../witch.js"
import { turkeySound } from "../turkey.js"
import { hohohoSound } from "../hohoho.js"
import CarDisplay from "../components/CarDisplay";

export default {
  components: { Container, Modal, CarDisplay },
  data() {
    return {
      currentTeachers: [],
      truey: true,
      modalDisplay: false,
      modalData: null,
      markStudentsCleared: false,
    };
  },
  computed: {
    ...mapGetters({
      students: "getStudents",
      teachers: "getTeachers",
      dismissalProviders: "getDismissalProviders",
      user: "user",
      schoolMeta: "getSchoolMeta",
    }),
    defaultTeacher: function () {
      const { email } = this.user.data;
      return this.teachers.find((x) => x.email === email);
    },
    filteredStudents() {
      // default with no selected teacher
      if (
        this.currentTeachers === undefined ||
        this.currentTeachers.length === 0
      ) {
        return this.students.filter(({ state }) =>
          ["ARRIVED", "STUDENT_IN_TRANSIT"].includes(state)
        );
      } else {
        return this.students.filter(
          ({ classroom, state }) =>
            this.currentTeachers.map((x) => x.name).includes(classroom) &&
            ["ARRIVED", "STUDENT_IN_TRANSIT"].includes(state)
        );
      }
    },
    filteredStudentsCleared() {
      // default with no selected teacher
      if (
        this.currentTeachers === undefined ||
        this.currentTeachers.length === 0
      ) {
        return this.students.filter(({ state }) =>
          state === "CLEARED"
        );
      } else {
        return this.students.filter(
          ({ classroom, state }) =>
            this.currentTeachers.map((x) => x.name).includes(classroom) &&
            state === "CLEARED"
        );
      }
    },
    filteredBusses() {
      return this.dismissalProviders.filter(({ state }) => state === "ARRIVED");
    },
  },
  watch: {
    defaultTeacher(val) {
      if (defaultTeacher !== undefined) this.currentTeachers = [val];
    },
    truey(val) {
      if (!val) this.$nextTick(() => (this.truey = true));
    },
    filteredStudents(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.playNotificationSound()
      }
    },
    filteredBusses(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.playNotificationSound()
      }
    },
    markStudentsCleared(newVal, oldVal) {
      this.setStudentsClearedToggle(newVal);
    }
  },
  mounted() {
    if (this.defaultTeacher !== undefined)
      this.currentTeachers = [this.defaultTeacher];
  },
  methods: {
    setCarState(studentID, state) {
      this.$store.dispatch("updateStudentFast", {
        studentID: studentID,
        studentState: state,
      });

      this.$emit("set");
    },
    showModal(studentID) {
      this.modalData = this.students.find((x) => x.id == studentID);
      this.modalDisplay = true;
    },
    playNotificationSound() {
      const halloween = new Date("October 31");
      var date = new Date()
      if (date.getDate() == halloween.getDate() && date.getMonth() == halloween.getMonth()) {
        witchSound();
      } else if (this.isWithin10DaysBeforeThanksgiving()) {
        turkeySound();
      } else if (this.isWithin14DaysBeforeChristmas()) {
        hohohoSound();
      } else {
        messageIncomingSound();
      }
      
    },
    isWithin10DaysBeforeThanksgiving() {
      // Get today's date
      var today = new Date();

      // Get the current year
      var currentYear = today.getFullYear();

      // Calculate Thanksgiving Day for the current year
      var thanksgivingDay = new Date(currentYear, 10, 1); // November is 10th month
      thanksgivingDay.setDate(thanksgivingDay.getDate() + (4 - thanksgivingDay.getDay() + 7) % 7 + 21);

      // Calculate the date 10 days before Thanksgiving
      var tenDaysBeforeThanksgiving = new Date(thanksgivingDay);
      tenDaysBeforeThanksgiving.setDate(tenDaysBeforeThanksgiving.getDate() - 10);

      // Check if today is within 10 days before Thanksgiving
      return today >= tenDaysBeforeThanksgiving && today <= thanksgivingDay;
    },
    isWithin14DaysBeforeChristmas() {
      // Get today's date
      var today = new Date();
      var christmasDay = new Date(today.getFullYear(), 11, 25); // December is the 11th month, 0 indexed
      var fourteenDaysBeforeChristmas = new Date(christmasDay);
      fourteenDaysBeforeChristmas.setDate(christmasDay.getDate() - 14);
      return today >= fourteenDaysBeforeChristmas && today <= christmasDay;
    },
    setStudentsClearedToggle(isCleared) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 7); // Set expiry to 7 days from now
      document.cookie = `MARK_STUDENTS_CLEARED_TOGGLE_CLASSROOM_VIEW=${isCleared}; expires=${expires.toUTCString()}; path=/`;
    },
    getStudentsClearedToggle() {
      const cookies = document.cookie.split(';');
      for (const cookie of cookies) {
        const [cookieKey, value] = cookie.trim().split('=');
        if (cookieKey === 'MARK_STUDENTS_CLEARED_TOGGLE_CLASSROOM_VIEW') {
          return value === 'true'; // Convert string back to boolean
        }
      }
      return false; // Default to false if cookie not found
    }
  },
  mounted() {
    this.markStudentsCleared = this.getStudentsClearedToggle()
  },
};
</script>

<style lang="scss">
.list-complete-item {
  transition: all 0.5s;
}

.list-complete-enter,
.list-complete-leave-to

/* .list-complete-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>